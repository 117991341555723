export default {
  "mandate-new-no-mandate": "cSA",
  "left-side": "cSY",
  "left-side__wrapper": "cSh",
  "left-side__wrapper-title": "cSf",
  "left-side__wrapper-details": "cSK",
  "left-side__wrapper-details-item": "cSG",
  "right-side": "cSr",
  "multi-accounts": "cSb",
  "right-side__wrapper": "cSy",
  "right-side__wrapper-name": "cSU",
  "right-side__wrapper-address": "cSj",
  "right-side__wrapper-iban-dashed": "cSW",
  "right-side__wrapper-address-label": "cSu",
  "right-side__wrapper-iban-label": "ccS",
  "right-side__wrapper-bic-label": "ccc",
  "right-side__wrapper-address-dashed": "ccq",
  "right-side__wrapper-bic-dashed": "ccZ",
  "dropdown-icon": "ccR",
  "dropdown": "ccQ",
  "multi-accounts-illustration": "cce"
};
