export default {
  "header-cell": "css",
  "empty": "csF",
  "header-content": "csg caption-bold",
  "active": "csD",
  "align-right": "csV",
  "first-col": "csL",
  "mid-col": "csT",
  "status-col": "csz"
};
