export default {
  "checks": "jC",
  "content": "jk",
  "form": "jJ",
  "title": "js",
  "description": "jF",
  "wrapper": "jg",
  "aside": "jD",
  "amount-field": "jV"
};
