export default {
  "notifications-settings": "cLP",
  "opened": "cLl",
  "icon": "cLX",
  "header": "cLC",
  "header-toggle": "cLk",
  "avatar-image": "cLJ",
  "title": "cLs",
  "toggle": "cLF"
};
