export default {
  "navigation-dropdown": "cVn",
  "expanded": "cVi",
  "trigger": "cVA",
  "trigger-title-icon": "cVY",
  "collapsed": "cVh",
  "responsive": "cVf",
  "logo": "cVK",
  "active": "cVG",
  "trigger-title": "cVr",
  "content": "cVb",
  "content-container": "cVy",
  "dropdown-icon": "cVU",
  "link": "cVj"
};
