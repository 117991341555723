/* import __COLOCATED_TEMPLATE__ from './invoice.hbs'; */
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { PAY_LATER_FLOW_ORIGIN, PAY_LATER_VALIDATION_ERRORS } from 'qonto/constants/financing';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

const PAY_LATER_BLOCKING_ERRORS = [
  PAY_LATER_VALIDATION_ERRORS.PREVENT_FF,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_LESS_THAN_THRESHOLD,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_MORE_THAN_AVAILABLE_CREDIT,
  PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE,
];

export default class FlowsTransfersSepaNewPayByInvoiceComponent extends Component {
  @service abilities;
  @service modals;
  @service intl;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service financing;
  @service router;
  @service toastFlashMessages;

  @tracked isValidationEnabled = false;

  get beneficiary() {
    return this.args.context.sepaTransfer.get('beneficiary').content;
  }

  get hasMissingBeneficiaryError() {
    return !this.beneficiary;
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  redirectToPayLaterFlowTask = dropTask(async close => {
    let { context } = this.args;

    this.segment.track('pay_later-lower_balance_continue_clicked', {
      origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_INVOICE,
    });

    await close();

    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE,
        invoiceId: context.invoice.id,
        transferId: context.sepaTransfer.idempotencyKey,
      },
    });
  });

  onClickNextTask = dropTask(async sepaTransfer => {
    let { context, transitionToNext } = this.args;

    await sepaTransfer.validate();

    let { amount, bankAccount, reference, scheduledDate } = sepaTransfer.validations.attrs;

    if (
      bankAccount.isInvalid ||
      reference.isInvalid ||
      scheduledDate.isInvalid ||
      this.hasMissingBeneficiaryError
    ) {
      this.isValidationEnabled = true;
      this._scrollToErrorField();
      return;
    }

    if (context.isPayLaterEligible) {
      try {
        context.didValidateFinancing = false;
        await this.validateFinancingTask.perform();
        context.didValidateFinancing = true;
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
          this.sentry.captureException(error);
        }
      }
    }

    this.isValidationEnabled = true;

    if (amount.isInvalid) {
      this.handleLowBalanceTask.perform().catch(ignoreCancelation);

      this._scrollToErrorField();
      return;
    }

    let { origin } = context;

    this.segment.track('transfer-sepa_amount_submitted', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });

    transitionToNext();
  });

  validateFinancingTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer, invoice } = context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    let attachmentId = invoice.attachment.id;
    let beneficiary = sepaTransfer.belongsTo('beneficiary').value();
    let iban = beneficiary.iban;
    let beneficiaryName = beneficiary.name;
    let selectedAccountIban = sepaTransfer.bankAccount.iban;
    let scheduledDate = sepaTransfer.scheduledDate;
    let operationType = sepaTransfer.operationType;

    context.payLaterErrors = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      selectedAccountIban,
      scheduledDate,
      operationType,
    });

    this.segment.track('pay-later_transfer-flow_errors_detected', {
      error: context.payLaterErrors || ['no_error'],
    });
  });

  handleLowBalanceTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer } = context;

    let isLowBalanceScenario =
      Number(sepaTransfer.amount) > Number(sepaTransfer.bankAccount.authorizedBalance);

    if (!context.isPayLaterEligible || !isLowBalanceScenario) return;

    if (!context.didValidateFinancing) {
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }

    if (!PAY_LATER_BLOCKING_ERRORS.some(error => context.payLaterErrors?.includes(error))) {
      await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
        confirmTask: this.redirectToPayLaterFlowTask,
      });
    }
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}
