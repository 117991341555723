/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class AccountReceivableOnboardingSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service flow;

  get shouldDisplayGotoRecurringInvoicesLink() {
    return this.flow.refererPage.includes('invoice-subscriptions');
  }
}
