export default {
  "donut-chart-container": "czK",
  "donut-chart-wrapper": "czG",
  "chart": "czr",
  "donut-chart-legend-container": "czb caption",
  "donut-chart-legend-item-empty": "czy",
  "donut-chart-legend-bullet-empty": "czU",
  "donut-chart-legend-bullet": "czj",
  "donut-chart-legend-text-empty": "czW",
  "donut-chart-legend-text": "czu",
  "donut-chart-custom-label-uncategorized": "caS",
  "custom-label-uncategorized-button": "cac body-1",
  "donut-chart-legend": "caq",
  "donut-chart-legend-item": "caZ",
  "donut-chart-legend-other-item": "caR",
  "donut-chart-legend-uncategorized-item": "caQ",
  "donut-chart-legend-text-name": "cae",
  "tooltip": "caB"
};
