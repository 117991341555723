export default {
  "custom-period-wrapper": "caN",
  "fadeIn": "cav",
  "custom-period-description": "cao body-2",
  "header": "can",
  "arrow-left": "cai",
  "body": "caA",
  "period-wrapper": "caY",
  "back-button": "cah",
  "footer": "caf"
};
