/* import __COLOCATED_TEMPLATE__ from './reason.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { wait } from 'ember-animated';
import { easeIn, easeOut } from 'ember-animated/easings/cosine';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

export default class SubscriptionCloseReasonComponent extends Component {
  @tracked showError = false;
  @tracked selectedReason = null;
  @tracked bannerVisible = false;
  @tracked currentPlan = null;
  @tracked suggestedPlan = null;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service router;
  @service organizationManager;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service store;

  constructor() {
    super(...arguments);
    this.selectedReason = this.args.context.selectedReason || null;
  }

  #formatPrice(price) {
    if (!price) return '';
    return this.intl.formatNumber(Number(price.perMonthAmount.value), {
      currency: price.perMonthAmount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      notation: 'compact',
    });
  }

  getPlanPrice(planType, priceType) {
    let plan;
    switch (planType) {
      case 'current':
        plan = this.currentPlan;
        break;
      case 'suggested':
        plan = this.suggestedPlan;
        break;
      default:
        return '';
    }

    let price;
    switch (priceType) {
      case 'monthly':
        price = plan?.monthlyPrice;
        break;
      case 'annual':
        price = plan?.annualPrice;
        break;
      default:
        return '';
    }

    return this.#formatPrice(price);
  }

  get currentPlanPriceMonthly() {
    return this.getPlanPrice('current', 'monthly');
  }

  get currentPlanPriceAnnual() {
    return this.getPlanPrice('current', 'annual');
  }

  get suggestedPlanPriceMonthly() {
    return this.getPlanPrice('suggested', 'monthly');
  }

  get suggestedPlanPriceAnnual() {
    return this.getPlanPrice('suggested', 'annual');
  }

  get suggestedPlanBillingInfo() {
    if (!this.suggestedPlan || !this.suggestedPlan.annualPrice) return '';
    return this.suggestedPlan.annualPrice.billingRecurrence;
  }

  get currentPlanBillingInfo() {
    if (!this.currentPlan || !this.currentPlan.annualPrice) return '';
    return this.currentPlan.annualPrice.billingRecurrence;
  }

  get suggestedDowngradePlan() {
    let downgradeMapping = {
      solo_smart: 'solo_basic',
      solo_premium: 'solo_smart',
      team_business: 'team_essential',
      team_enterprise: 'team_business',
    };

    let currentPlanCode = this.subscriptionManager.currentPricePlan.groupCode;
    return downgradeMapping[currentPlanCode] || null;
  }

  fetchDowngradePlansTask = dropTask(async () => {
    let currentPlanGroup = this.subscriptionManager.currentPricePlan.groupCode;
    let suggestedPlanCode = this.suggestedDowngradePlan;

    if (!suggestedPlanCode) {
      return;
    }

    try {
      let allPricePlans = await this.store.query('subscription-product', { type: 'core' });

      this.currentPlan = allPricePlans.find(plan => plan.code.includes(currentPlanGroup));

      this.suggestedPlan = allPricePlans.find(plan => plan.code.includes(suggestedPlanCode));
    } catch (error) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  get bannerType() {
    let { currentSubscription, currentPricePlan } = this.subscriptionManager;
    let recurrence = currentSubscription?.recurrence;
    let currentPlanGroup = currentPricePlan?.groupCode;
    let selectedReasonKey = this.selectedReason?.key;

    if (selectedReasonKey !== 'account_fees') {
      return null;
    }

    let isEligibleForAnnualDowngrade =
      this.suggestedDowngradePlan && !['solo_basic', 'team_essential'].includes(currentPlanGroup);

    let isPartOfABTest = variation('feature--improve-retention-ab-test');
    let isFeatureSliceOneEnabled = variation('feature--improve-retention-slice-1');

    if (!isFeatureSliceOneEnabled) {
      return null;
    }

    if (recurrence === 'annual' && isEligibleForAnnualDowngrade) {
      return 'annual-downgrade-plan';
    }

    if (recurrence === 'monthly') {
      let isOnEligiblePlan = ['solo_basic', 'team_essential'].includes(currentPlanGroup);

      if (isOnEligiblePlan) {
        return 'switch-to-annual';
      } else {
        return isPartOfABTest ? 'switch-to-annual' : 'downgrade-plan';
      }
    }

    return null;
  }

  get shouldShowBanner() {
    if (this.bannerType === 'downgrade-plan' || this.bannerType === 'annual-downgrade-plan') {
      if (!this.fetchDowngradePlansTask.isRunning) {
        this.fetchDowngradePlansTask.perform().catch(() => {});
      }
    }
    return this.bannerType !== null;
  }

  *customFadeTransition({ insertedSprites, removedSprites, duration = 75, delay = 25 }) {
    for (let sprite of insertedSprites) {
      yield wait(delay);
      fadeIn(sprite, { duration, easing: easeIn });
    }

    for (let sprite of removedSprites) {
      yield wait(delay);
      fadeOut(sprite, { duration, easing: easeOut });
    }
  }

  get hasError() {
    let { hasReasonError, subReasonError } = this;
    return hasReasonError || subReasonError;
  }

  get hasReasonError() {
    let { closingReasonSubCategory } = this.args.context;
    return !closingReasonSubCategory;
  }

  get subReasonError() {
    let { closingReasonDetails, closingReasonSubCategory } = this.args.context;
    return closingReasonSubCategory?.items?.length && !closingReasonDetails
      ? this.intl.t('subscription.account-closing.reason.select-reason')
      : null;
  }

  @action selectReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonSubCategory = item;
    context.closingReasonDetails = null;
    context.closingReasonDetailsOther = null;
    this.selectedReason = item;
    this.bannerVisible = this.shouldShowBanner;
  }

  @action selectSubReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonDetails = item;
    context.closingReasonDetailsOther = null;
  }

  @action onSubmit(e) {
    e.preventDefault();
    if (this.hasError) {
      this.showError = true;
      return;
    }

    let { closingReasonDetails, closingReasonDetailsOther } = this.args.context;
    this.segment.track('account_closing_additional_details_completed', {
      reason_details: closingReasonDetails?.key,
      reason_details_other: closingReasonDetailsOther,
    });
    this.args.transitionToNext();
  }

  @action
  transitionToSubscription() {
    let recurrence =
      this.bannerType === 'switch-to-annual'
        ? 'annual'
        : this.subscriptionManager.currentSubscription?.recurrence;
    this.args.transitionToFlow({
      flowName: 'subscription-change',
      stepId: 'plans',
      queryParams: {
        recurrence,
      },
    });
  }
}
