export default {
  "member-details": "cgt",
  "header": "cgM",
  "no-email": "cgP",
  "header-img": "cgl",
  "invited-persona": "cgX",
  "revoked-persona": "cgC",
  "invited-icon": "cgk",
  "revoked-icon": "cgJ",
  "revised": "cgs",
  "invitable-icon": "cgF",
  "dropdown": "cgg",
  "header-body": "cgD",
  "status": "cgV",
  "name": "cgL title-3",
  "email": "cgT caption-bold",
  "actions": "cgz body-2",
  "actions-cards": "cga",
  "actions-transactions": "cgH",
  "body": "cgO",
  "expense-permissions-section": "cgm",
  "body-title": "cgp title-4",
  "body-attr": "cgx",
  "body-label": "cgw",
  "attr-buttons": "cgN",
  "body-role": "cgv small",
  "details-actions": "cgo",
  "actions-item": "cgn",
  "disclaimer": "cgi",
  "permissions": "cgA",
  "permission": "cgY",
  "permission-label": "cgh",
  "permission-check": "cgf",
  "permission-missing": "cgK",
  "editable-section": "cgG",
  "hris-placeholder": "cgr",
  "imported-from": "cgb",
  "integration-logo": "cgy",
  "permission-value": "cgU",
  "tooltip": "cgj",
  "check-icon": "cgW"
};
