export default {
  "row": "hY",
  "cell": "hh",
  "cell-content": "hf",
  "supplier": "hK",
  "next-installment": "hG",
  "installment-amount": "hr",
  "total-repayment": "hb",
  "cell-details": "hy",
  "align-right": "hU",
  "empty": "hj"
};
