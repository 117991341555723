export default {
  "wrapper": "vJ",
  "content": "vs",
  "compact": "vF",
  "full-width": "vg",
  "left": "vD",
  "right-image": "vV",
  "right-lottie": "vL",
  "badge": "vT",
  "badge-icon": "vz",
  "animation": "va",
  "hidden": "vH"
};
