/* import __COLOCATED_TEMPLATE__ from './organization-scope.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';
import { MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH } from 'qonto/constants/member/account-management';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class MemberInviteAccountManagementOrganizationScopeComponent extends Component {
  @service organizationManager;
  @service intl;
  @service segment;
  @service sensitiveActions;
  @service sentry;
  @service toastFlashMessages;
  @service abilities;

  @tracked bankAccountsError;
  @tracked allowedAccounts = this.memberAllowedBankAccounts;
  @tracked isFinancialMonitoringChecked =
    this.memberCustomPermissions.groups[this._permissionKeys.financialMonitoring];
  @tracked isBookkeepingChecked =
    this.memberCustomPermissions.groups[this._permissionKeys.bookkeeping];

  _permissionKeys = {
    financialMonitoring: 'access_bank_accounts_statements',
    bookkeeping: 'manage_bookkeepings',
  };

  get bankAccounts() {
    return this.organizationManager.organization.sortedAlphabeticallyInternalAccounts;
  }

  get isSearchEnabled() {
    return this.bankAccounts.length > MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH;
  }

  @cached
  get bankAccountsOptions() {
    return this.bankAccounts.map(account => ({
      key: account.id,
      value: account.name,
      authorizedBalance: account.authorizedBalance,
      balanceCurrency: account.balanceCurrency,
      closed: account.status === ACCOUNT_STATUS.CLOSED,
    }));
  }

  get member() {
    return this.args.context.member;
  }

  get memberAllowedBankAccountsIds() {
    return this.member.allowedBankAccountsIds;
  }

  get memberCustomPermissions() {
    return this.member.customPermissions;
  }

  get memberAllowedBankAccounts() {
    return this.bankAccountsOptions.filter(account =>
      this.memberAllowedBankAccountsIds?.includes(account.key)
    );
  }

  get hasModularPricing() {
    return this.organizationManager?.organization?.hasModularPricing;
  }

  get isAdvancedManager() {
    return this.member.role === ROLES.ADVANCED_MANAGER;
  }

  get shouldShowRestrictedPermissions() {
    return !this.hasModularPricing || this.isAdvancedManager;
  }

  @action
  updateAllowedBankAccountIds(selectedAccounts) {
    this.resetBankAccountsError();
    this.allowedAccounts = selectedAccounts;
    let selectedAccountIds = selectedAccounts?.map(account => account.key);
    this.member.allowedBankAccountsIds = selectedAccountIds ?? [];
  }

  submitTask = dropTask(async () => {
    let { transitionToNext, stepList } = this.args;
    let [firstStep] = stepList;

    let hasActiveBankAccount = this.memberAllowedBankAccounts.some(account => !account.closed);

    if (
      !this.memberAllowedBankAccountsIds ||
      !this.memberAllowedBankAccountsIds.length ||
      !hasActiveBankAccount
    ) {
      this.bankAccountsError = this.intl.t(
        'invitation-flow.permissions.accounts.validation.active-bank-account'
      );
      return;
    }

    this.segment.track('team_invite_account-permissions_set');
    this.segment.track('team_invite_account-list_set', {
      no_of_accounts: this.memberAllowedBankAccountsIds.length,
    });

    if (!this.args.context.isEditingMember) {
      return transitionToNext();
    }

    if (!['account-management-team', 'account-management-organization'].includes(firstStep.id)) {
      return transitionToNext();
    }

    // If the first step is account-management-team or account-management-organization
    // it means that the user is in the account permissions flow, hence we need to save the task
    await this.sensitiveActions.runTask.perform(this.saveTask);
  });

  saveTask = dropTask(async () => {
    let { context, transitionToNext } = this.args;

    try {
      if (context.role?.key) {
        context.member.role = context.role.key;
      }

      await context.member.updateRole();
    } catch (error) {
      context.member.role = context.currentUserRole;

      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      return;
    }

    try {
      await context.member.updateAllowedBankAccounts(context.member.allowedBankAccountsIds);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      context.isMultipleStepsFlow = true;
      context.hasAllowedBankAccountsError = true;
    }

    if (!context.hasAllowedBankAccountsError) {
      this.segment.track('member_permissions-update_confirmed');
    }

    transitionToNext();
  });

  @action
  updatePermission(key) {
    switch (key) {
      case this._permissionKeys.financialMonitoring:
        this.isFinancialMonitoringChecked = !this.isFinancialMonitoringChecked;
        this.setCustomPermission(key, this.isFinancialMonitoringChecked);
        break;
      case this._permissionKeys.bookkeeping:
        this.isBookkeepingChecked = !this.isBookkeepingChecked;
        this.setCustomPermission(key, this.isBookkeepingChecked);
        break;
    }
  }

  @action
  setCustomPermission(key, value) {
    this.memberCustomPermissions.groups = {
      ...this.memberCustomPermissions.groups,
      [key]: value,
    };
  }

  resetBankAccountsError() {
    this.bankAccountsError = null;
  }
}
