export default {
  "banner": "czt",
  "content": "czM",
  "title-row": "czP",
  "title": "czl title-3",
  "badge": "czX",
  "text": "czC body-2",
  "missing-info-disclaimer": "czk",
  "button": "czJ btn btn--primary",
  "spinner": "czs",
  "image": "czF"
};
