export default {
  "card": "cTY",
  "header": "cTh",
  "avatar": "cTf",
  "title": "cTK",
  "subtitle": "cTG",
  "label": "cTr",
  "item": "cTb body-2",
  "header-icon": "cTy",
  "dropdown-menu": "cTU",
  "menu-item": "cTj body-2",
  "delete": "cTW"
};
