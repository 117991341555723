export default {
  "account-description": "cpa",
  "account-identification": "cpH",
  "account-avatar": "cpO",
  "account-name": "cpm",
  "account-balance": "cpp body-2",
  "status": "cpx",
  "external-accounts-disclaimer": "cpw",
  "failed-connection": "cpN"
};
