/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  LOCAL_STORAGE_SEPA_PAY_LATER_NEW_BADGE_DISPLAYED_KEY,
  PAY_LATER_FLOW_ORIGIN,
} from 'qonto/constants/financing';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class FlowsTransfersSepaNewDetailsComponent extends Component {
  @service segment;
  @service abilities;
  @service modals;

  @tracked isValidationEnabled = false;

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  get showPayLaterToggle() {
    if (!variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary')) return false;

    return (
      this.abilities.can('view pay later toggle in financing') &&
      this.args.context.isPayLaterEligible
    );
  }

  get origin() {
    return PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY;
  }

  get isPayLaterToggleDisabled() {
    let organization = this.sepaTransfer.get('organization');

    return organization?.get('hasPreventPayLaterToggle') === true;
  }

  get isPayLaterNewBadgeDisplayed() {
    let organization = this.sepaTransfer.get('organization');

    if (this.isPayLaterToggleDisabled) return false;

    return !safeLocalStorage.getItem(
      `${LOCAL_STORAGE_SEPA_PAY_LATER_NEW_BADGE_DISPLAYED_KEY}_${organization.id}`
    );
  }

  onClickNextTask = dropTask(async sepaTransfer => {
    await sepaTransfer.validate();

    this.isValidationEnabled = true;

    let { reference, bankAccount, amount, scheduledDate } = sepaTransfer.validations.attrs;

    if (reference.isInvalid || bankAccount.isInvalid || scheduledDate.isInvalid) {
      this._scrollToErrorField();
      return;
    }

    if (amount.isInvalid) {
      this._scrollToErrorField();

      let shouldShowLowBalanceModal =
        this.showPayLaterToggle && !this.args.context.isPayLater && !this.isPayLaterToggleDisabled;

      if (shouldShowLowBalanceModal) {
        this.handleLowBalanceTask.perform().catch(ignoreCancelation);
      }

      if (!this.args.context.isPayLater) {
        return;
      }
    }

    let { context, transitionToNext } = this.args;
    let { origin, isPayLater } = context;

    this.segment.track('transfer-sepa_amount_submitted', {
      ...(origin && { origin }),
    });

    if (variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary') && isPayLater) {
      let organization = this.sepaTransfer.get('organization');

      safeLocalStorage.setItem(
        `${LOCAL_STORAGE_SEPA_PAY_LATER_NEW_BADGE_DISPLAYED_KEY}_${organization.id}`,
        true
      );

      return this.args.transitionToFlow({
        flowName: 'pay-later-transfer',
        stepId: 'invoice-upload',
        queryParams: {
          transferId: sepaTransfer.idempotencyKey,
          origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        },
      });
    }

    transitionToNext();
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  @action
  togglePayLater() {
    let { context } = this.args;

    context.isPayLater = !context.isPayLater;
    this.segment.track('pay_later-toggle_switch', {
      state: context.isPayLater ? 'on' : 'off',
    });
  }

  handleLowBalanceTask = dropTask(async () => {
    let isLowBalanceScenario =
      Number(this.sepaTransfer.amount) > Number(this.sepaTransfer.bankAccount.authorizedBalance);

    if (!isLowBalanceScenario) return;

    await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
      confirmTask: this.redirectToPayLaterFlowTask,
    });
  });

  redirectToPayLaterFlowTask = dropTask(async close => {
    this.segment.track('pay_later-lower_balance_continue_clicked', {
      origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
    });

    await close();

    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        transferId: this.sepaTransfer.idempotencyKey,
      },
    });
  });
}
