export default {
  "step-container": "cEH",
  "budget-tooltip": "cEO",
  "content": "cEm",
  "summary-description": "cEp",
  "detail": "cEx",
  "separator": "cEw",
  "detail-value": "cEN",
  "color-secondary": "cEv",
  "beneficiary-info": "cEo",
  "currency": "cEn",
  "fees-tooltip": "cEi"
};
