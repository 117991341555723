export default {
  "container": "cev",
  "has-background": "ceo",
  "wrapper": "cen",
  "error-wrapper": "cei",
  "bottom-btns": "ceA",
  "bottom-wrapper": "ceY",
  "tab-nav": "ceh",
  "tab-nav-small": "cef",
  "btn-clear": "ceK",
  "error-illustration": "ceG",
  "error-title": "cer title-1",
  "disclaimer-wrapper": "ceb",
  "disclaimer": "cey",
  "error-description": "ceU body-2",
  "table-wrapper": "cej",
  "title": "ceW title-1",
  "lineup-toggle": "ceu",
  "description": "cBS body-2",
  "loading-wrapper": "cBc",
  "cards-wrapper": "cBq",
  "card": "cBZ",
  "plan": "cBR",
  "placeholder-header": "cBQ",
  "disclaimer-info": "cBe",
  "spinner": "cBB"
};
