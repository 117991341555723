export default {
  "mandates": "vo",
  "mandates-list": "vn",
  "left-section": "vi",
  "header-search": "vA",
  "header": "vY",
  "header-content": "vh",
  "search": "vf",
  "search-bar": "vK",
  "body": "vG",
  "list-title": "vr caption-bold",
  "details": "vb",
  "avatar": "vy mr-16",
  "header-beneficiary": "vU",
  "header-beneficiary-activity": "vj",
  "header-beneficiary-details": "vW",
  "header-beneficiary-details-recurring": "vu",
  "infos": "oS",
  "infos-title": "oc",
  "infos-empty": "oq",
  "infos-list": "oZ",
  "infos-list-item": "oR",
  "infos-list-item-left": "oQ",
  "infos-list-item-right": "oe",
  "infos-list-item-attachment": "oB"
};
