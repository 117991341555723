export default {
  "step-container": "uR",
  "content": "uQ",
  "detail": "ue",
  "secondary": "uB",
  "reference": "uE",
  "fees-label": "ud",
  "fees-tooltip": "uI",
  "separator": "ut",
  "mt-32": "uM"
};
