export default {
  "bullet": "cHE",
  "slice-0": "cHd",
  "slice-1": "cHI",
  "slice-2": "cHt",
  "slice-3": "cHM",
  "slice-4": "cHP",
  "slice-5": "cHl",
  "slice-6": "cHX",
  "slice-other": "cHC",
  "slice-uncategorized": "cHk",
  "label": "cHJ",
  "ellipsis": "cHs",
  "rest": "cHF",
  "previous-date-range": "cHg caption"
};
