export default {
  "color-option": "ctH",
  "color-dot": "ctO",
  "any": "ctm",
  "red": "ctp",
  "orange": "ctx",
  "yellow": "ctw",
  "green": "ctN",
  "blue": "ctv",
  "purple": "cto",
  "pink": "ctn",
  "black": "cti",
  "grey": "ctA",
  "brown": "ctY",
  "color-name": "cth"
};
