export default {
  "container": "Kk",
  "summary": "KJ",
  "sideview": "Ks",
  "steps-container": "KF",
  "invoice-upload-container": "Kg",
  "pdf-preview": "KD",
  "uploader": "KV",
  "file-dropzone": "KL",
  "overlay": "KT",
  "footer": "Kz",
  "upload-prompt": "Ka",
  "step-selector": "KH",
  "disclaimer": "KO"
};
