export default {
  "container": "US",
  "disabled-option": "Uc",
  "content": "Uq",
  "spinner": "UZ",
  "subtitle": "UR",
  "details-table": "UQ",
  "caption": "Ue",
  "tooltip-icon": "UB",
  "tooltip-icon-right": "UE",
  "card-monthly-cost": "Ud",
  "card-monthly-discounted-cost": "UI",
  "strikethrough": "Ut",
  "purple-text": "UM",
  "budget-label": "UP",
  "card-cost-label": "Ul",
  "card-billing-account": "UX"
};
