/* import __COLOCATED_TEMPLATE__ from './org-list.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { allSettled, dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { companyCreationJsURL, registerJsURL, registerPartnersJsURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export const isAccountant = role => role === ROLES.REPORTING;
export const not = fn => role => !fn(role);
export const findInList =
  idList =>
  isRoleFn =>
  ({ id, role }) =>
    idList.includes(id) && isRoleFn(role);

export default class ListOrgaComponent extends Component {
  @service organizationManager;
  @service userManager;
  @service store;
  @service router;
  @service segment;
  @service sentry;

  companyCreationJsURL = companyCreationJsURL;
  registerPartnersJsURL = registerPartnersJsURL;
  registerJsURL = registerJsURL;

  @reads('organizationManager.organization') currentOrganization;

  get companyCreationOrganizations() {
    let creationOrganizations = this.organizationManager.companyCreationOrganizations;
    if (creationOrganizations) {
      return [...creationOrganizations].sort(sortByKey('name'));
    }
  }

  get partnerOrganizations() {
    let partnerOrganizations = this.organizationManager.partnerOrganizations;
    if (partnerOrganizations) {
      return [...partnerOrganizations].sort(sortByKey('legalName'));
    }
  }

  get registeringOrganizations() {
    // these are not an EmberArray since they're loaded through the network-manager
    let { registeringOrganizations } = this.organizationManager;
    if (registeringOrganizations) {
      return [...registeringOrganizations].sort(sortByKey('name'));
    }
  }

  get organizations() {
    let { accountantHubMode } = this.args;
    let organizationsEnabled;

    if (accountantHubMode) {
      let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);
      let findMatchingMember = findInList(userMembershipsIds);
      let filterMembersWithRole =
        accountantHubMode === 'include'
          ? organization => organization.get('memberships').find(findMatchingMember(isAccountant))
          : organization =>
              organization.get('memberships').find(findMatchingMember(not(isAccountant)));

      organizationsEnabled =
        this.userManager.currentUser.organizationsEnabled.filter(filterMembersWithRole);
    } else {
      organizationsEnabled = this.userManager.currentUser.organizationsEnabled;
    }

    if (organizationsEnabled) {
      // Proxies properties need to be accessed with .get()
      return [...organizationsEnabled].sort((a, b) => a.get('name').localeCompare(b.get('name')));
    }
  }

  constructor() {
    super(...arguments);
    // Task already handles errors
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchPricePlansTask.perform();
  }

  fetchPricePlansTask = dropTask(async () => {
    let pricePlans = await allSettled(
      this.userManager.currentUser.organizationsEnabled.map(async organization => {
        let id = organization.get('id');
        let pricePlan, sub;
        if (organization.get('hasModularPricing')) {
          sub = this.store.peekAll('organization-subscription-new').find(subscription => {
            return (
              get(subscription, 'organization.id') === id &&
              get(subscription, 'product.productType') === 'core'
            );
          });

          if (!sub) {
            let allProductSubscriptions = await this.store
              .query(
                'organization-subscription-new',
                {
                  includes: ['product'],
                },
                {
                  adapterOptions: {
                    headers: {
                      'X-Qonto-Organization-ID': id,
                    },
                  },
                }
              )
              .catch(error => {
                if (error.status !== 404 && ErrorInfo.for(error).shouldSendToSentry) {
                  this.sentry.captureException(error);
                }
              });

            sub = allProductSubscriptions?.find(({ product }) => !product.isAddon);
          }
          pricePlan = sub?.product;
        } else {
          sub = this.store
            .peekAll('organization-subscription')
            .find(subscription => get(subscription, 'organization.id') === id);

          if (!sub) {
            sub = await this.store
              .queryRecord('organization-subscription', { organization_id: id })
              .catch(error => {
                if (error.status !== 404 && ErrorInfo.for(error).shouldSendToSentry) {
                  this.sentry.captureException(error);
                }
              });
          }
          pricePlan = await sub?.belongsTo('pricePlan').load();
        }

        return { organizationId: id, pricePlan };
      })
    );

    this.pricePlans = pricePlans.reduce((plans, { value }) => {
      if (value) {
        plans[value.organizationId] = value.pricePlan;
      }
      return plans;
    }, {});
  });

  @action
  trackOrgClick() {
    let origin = this.router.currentRouteName.includes('accounting-hub')
      ? 'accounting-hub'
      : 'organizations';
    this.segment.track('switch_organization', { origin });
  }
}
