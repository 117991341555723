export default {
  "header": "Ys",
  "header-top": "YF",
  "header-inner": "Yg",
  "disclaimer": "YD mb-16",
  "header-main-wrapper": "YV",
  "header-main": "YL",
  "logo": "YT mr-16",
  "header-right": "Yz",
  "partner-description": "Ya body-2"
};
