export default {
  "details-box": "cLi",
  "picto": "cLA",
  "picto-status": "cLY",
  "ghost": "cLh",
  "primary-info": "cLf",
  "date": "cLK",
  "account-infobox": "cLG",
  "declined-disclaimer": "cLr",
  "nrc-download": "cLb"
};
