export default {
  "container": "cVa",
  "close-button": "cVH",
  "content-wrapper": "cVO",
  "main": "cVm",
  "description": "cVp",
  "calculation-item": "cVx",
  "total-km": "cVw",
  "calculation-details": "cVN",
  "btn": "cVv"
};
