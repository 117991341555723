/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsMemberRoleSelectionItemComponent extends Component {
  badgeHighlight = Badge;

  @service memberManager;

  get isModularPricing() {
    let { role, hasModularPricing } = this.args;
    return (role.key === ROLES.MANAGER || role.key === ROLES.ADVANCED_MANAGER) && hasModularPricing;
  }

  @action onClick() {
    this.onClickTask.perform().catch(ignoreCancelation);
  }

  onClickTask = dropTask(async () => {
    let { context, transitionToNext, role, pushFlow } = this.args;
    await this.memberManager.handleSelectRoleTask
      .unlinked()
      .perform(context, role, transitionToNext, pushFlow);
  });
}
