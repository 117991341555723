export default {
  "card": "czq",
  "header": "czZ",
  "avatar": "czR",
  "title": "czQ",
  "subtitle": "cze",
  "label": "czB",
  "item": "czE body-2",
  "header-icon": "czd",
  "mt-8": "czI"
};
