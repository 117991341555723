export default {
  "mandate-list-item": "cFi",
  "selected": "cFA",
  "status-avatar": "cFY",
  "infos": "cFh",
  "info-title": "cFf body-1",
  "info-title-suspended": "cFK",
  "info-title-status-suspended": "cFG",
  "info-mandate-count": "cFr body-2"
};
