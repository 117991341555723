export default {
  "cell": "hE",
  "empty": "hd",
  "supplier": "hI",
  "supplier-content": "ht",
  "supplier-avatar": "hM mr-16",
  "installments": "hP body-2",
  "overflow-hidden": "hl",
  "ellipsis": "hX",
  "next-installment-date": "hC",
  "next-installment-amount": "hk body-2",
  "amount": "hJ",
  "disclaimer": "hs",
  "disclaimer-icon": "hF",
  "warning": "hg",
  "error": "hD",
  "total-repayment": "hV body-1"
};
