export default {
  "content": "cBH",
  "error-message": "cBO",
  "wrapper": "cBm",
  "banner": "cBp",
  "banner-content": "cBx",
  "banner-text": "cBw",
  "banner-image": "cBN",
  "banner-title": "cBv body-1",
  "banner-description": "cBo body-2",
  "link": "cBn",
  "plans-comparison": "cBi",
  "plan-box": "cBA",
  "arrow": "cBY",
  "arrow-icon": "cBh",
  "link-downgrade": "cBf",
  "stroke-text": "cBK"
};
