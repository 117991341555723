export default {
  "wrapper": "oU",
  "subtitle": "oj body-2",
  "legend": "oW",
  "table": "ou",
  "footer": "nS",
  "footer-codice-1": "nc",
  "footer-codice-2": "nq",
  "footer-total-a": "nZ",
  "footer-total-b": "nR",
  "footer-balance": "nQ",
  "errors": "ne"
};
