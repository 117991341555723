export default {
  "container": "cQL",
  "form-core": "cQT",
  "label": "cQz",
  "request-description": "cQa",
  "inline-inputs": "cQH",
  "placeholder-label": "cQO",
  "placeholder-input": "cQm",
  "placeholder-checkbox": "cQp"
};
