export default {
  "details": "cDO",
  "details--ff": "cDm",
  "header-container": "cDp",
  "icon": "cDx",
  "line-container": "cDw",
  "info-line": "cDN",
  "body-details": "cDv",
  "body-details-header": "cDo",
  "body-details-1": "cDn",
  "body-details-2": "cDi",
  "body-details-3": "cDA",
  "body-details-4": "cDY"
};
