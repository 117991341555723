export default {
  "checks": "jw",
  "wrapper": "jN",
  "form": "jv",
  "title": "jo",
  "wrapper-body": "jn",
  "aside": "ji",
  "steps-container": "jA",
  "step": "jY",
  "container-dot": "jh",
  "text": "jf",
  "text-title": "jK",
  "text-desc": "jG",
  "start-button": "jr"
};
