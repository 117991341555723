export default {
  "container": "yc",
  "container-metal": "yq",
  "content": "yZ",
  "button": "yR",
  "embossed-selector": "yQ",
  "carousel-container": "ye",
  "carousel": "yB",
  "asset": "yE",
  "poster": "yd",
  "video-embossed": "yI",
  "video": "yt",
  "active": "yM",
  "embossed": "yP",
  "safari": "yl",
  "poster-open": "yX"
};
