export default {
  "company-logo": "cLZ",
  "list-item": "cLR",
  "link": "cLQ",
  "title-wrapper": "cLe",
  "title": "cLB body-2",
  "subtitle": "cLE caption",
  "truncated-text": "cLd",
  "image-placeholder": "cLI"
};
