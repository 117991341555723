/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, race, rawTimeout } from 'ember-concurrency';

import {
  ONBOARDING_OCR_FAILED_EVENT,
  ONBOARDING_OCR_SUCCEDED_EVENT,
  ONBOARDING_SOURCE,
  ONBOARDING_STATUS,
  ONBOARDING_TRACKING_EVENTS,
  ONBOARDING_WEBSOCKET_TIMEOUT_MS,
} from 'qonto/constants/receivable-invoice';
import { OcrLoading } from 'qonto/react/components/account-receivable/summary/ocr-loading/ocr-loading';
import { StepSelector } from 'qonto/react/components/account-receivable/summary/step-selector/step-selector';

/**
 * @typedef {import("../../../../react/components/account-receivable/summary/step-selector/step-selector").StepSelectorProps} Step
 */

export default class FlowsAccountReceivableOnboardingSummary extends Component {
  stepSelector = StepSelector;
  ocrLoading = OcrLoading;

  @service intl;
  @service store;
  @service abilities;
  @service toastFlashMessages;
  @service notifierManager;
  @service subscriptionManager;
  @service router;
  @service modals;
  @service flow;
  @service segment;
  @service accountReceivableOnboardingUploadManager;

  @tracked isWaitingForOCR = false;
  @tracked hasOCRTimedOut = false;

  @tracked displayStepValidation = false;

  constructor() {
    super(...arguments);

    this.accountReceivableOnboardingUploadManager.shouldToastOnInvalidFile = true;
    this.accountReceivableOnboardingUploadManager.registerCallback({
      onUploadFinished: () => this.onFileUploadFinishTask.perform(),
      onUploadStarted: this.onUploadStarted,
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.accountReceivableOnboardingUploadManager.resetState();
  }

  @action
  goTo(stepId) {
    this.args.context.nextStepId = stepId;
    this.args.transitionToNext();
  }

  @action
  onUploadStarted() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.IMPORT_STARTED);
  }

  get onboardingState() {
    return this.args.context.onboardingState;
  }

  get organization() {
    return this.onboardingState.organization;
  }

  get settings() {
    return this.args.context.settings;
  }

  get customizationStepStatus() {
    return this.onboardingState?.stepStatusCustomization;
  }

  get nextInvoiceNumberPreview() {
    let { invoiceNumberingPattern, invoiceNextNumber } = this.settings;
    let month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    let fullYear = new Date().getFullYear();

    let prefix = invoiceNumberingPattern
      ?.replace(/\(MM\)/g, month)
      .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear);

    return prefix + invoiceNextNumber;
  }

  /**
   * TODO: Replace with conditional based translated content, actual statuses and actions
   *
   * @returns {Array<Step>}
   */
  get steps() {
    let { source, onboardingState, isItalianOrganization } = this.args.context;
    return [
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.numbering.title'),
        status: onboardingState.stepStatusNumbering,
        subtitle: this.numberingStepSubtitle,
        icon: 'invoice',
        onClick: () => {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
            source,
            step: 'numbering',
            status: onboardingState.stepStatusNumbering,
          });
          this.goTo('numbering');
        },
        dataTestSelector: 'numbering-step',
        disabled: this.isWaitingForOCR && !this.hasOCRTimedOut,
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.company-details.title.company'),
        status: onboardingState.stepStatusCompanyDetails,
        subtitle: this.companyDetailsStepSubtitle,
        icon: 'company',
        onClick: () => {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
            source,
            step: 'company-details',
            status: onboardingState.stepStatusCompanyDetails,
          });
          this.goTo(isItalianOrganization ? 'it-company-details' : 'company-details');
        },
        dataTestSelector: 'company-details-step',
        disabled: this.isWaitingForOCR && !this.hasOCRTimedOut,
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.customization.title'),
        subtitle: this.customizationStepSubtitle,
        status: this.customizationStepStatus,
        icon: 'pole',
        onClick: () => {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
            source,
            step: 'customization',
            status: this.customizationStepStatus,
          });
          this.goTo('customization-logo');
        },
        dataTestSelector: 'customization-step',
        disabled: this.isWaitingForOCR && !this.hasOCRTimedOut,
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.accountant-access.title'),
        status: this.accountantAccessStepStatus,
        subtitle: this.accountantAccessStepSubtitle,
        icon: 'calculator',
        onClick: () => {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
            source,
            step: 'accountant',
            status: this.accountantAccessStepStatus,
          });
          this.args.pushFlow('member-invite', 'personal-info');
        },
        dataTestSelector: 'accountant-access-step',
        disabled: this.isWaitingForOCR && !this.hasOCRTimedOut,
      },
    ];
  }

  get displayFileDropzone() {
    return (
      !this.onboardingState?.prefilledAt &&
      !this.isWaitingForOCR &&
      !this.onboardingState?.hasManuallyCompletedSteps
    );
  }

  get isEligibleForFreeTrial() {
    return Boolean(this.subscriptionManager.currentSubscription.availableTrials.length);
  }

  get accountantAccessStepStatus() {
    if (this.abilities.cannot('access accountant-access')) {
      return this.isEligibleForFreeTrial ? 'tryForFree' : 'upgrade';
    }

    return this.onboardingState.stepStatusAccountantAccess;
  }

  get numberingStepSubtitle() {
    return this.args.context.onboardingState.stepStatusNumbering === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.numbering.subtitle.completed', {
          firstInvoiceNumber: this.nextInvoiceNumberPreview,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.numbering.subtitle.empty');
  }

  get companyDetailsStepSubtitle() {
    let { legalCountry } = this.args.context;
    return this.args.context.onboardingState.stepStatusCompanyDetails === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.company-details.subtitle.completed', {
          legalCountry,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.company-details.subtitle.empty');
  }

  get customizationStepSubtitle() {
    let { legalCountry } = this.args.context;
    return this.customizationStepStatus === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.customization.subtitle.completed', {
          legalCountry,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.customization.subtitle.empty', {
          legalCountry,
        });
  }

  get accountantAccessStepSubtitle() {
    if (this.abilities.cannot('access accountant-access')) {
      return this.isEligibleForFreeTrial
        ? this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.free-trial')
        : this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.upgrade');
    }

    return this.onboardingState.stepStatusAccountantAccess === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.completed')
      : this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.empty');
  }

  get exampleInvoice() {
    return {
      organization: {
        locale: this.organization.locale,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        shortLegalForm: this.organization.shortLegalForm,
        legalCountry: this.organization.legalCountry,
        address: this.organization.address,
        contactEmail: this.organization.contactEmail,
        vatNumber: this.organization.vatNumber,
        taxNumber: this.organization.taxNumber,
      },
      bic: this.organization.mainAccount?.bic,
      iban: this.organization.mainAccount?.iban,
      beneficiaryName: this.organization.legalName,
    };
  }

  get reuploadCta() {
    if (this.onboardingState.hasManuallyCompletedSteps) {
      return this.intl.t('receivable-invoices.onboarding.invoice-upload.footer.upload.text');
    }

    return this.intl.t('receivable-invoices.onboarding.invoice-upload.footer.reupload.text');
  }

  get logo() {
    return this.organization.isDefaultAvatar === false ? this.organization.picture : null;
  }

  onFinishTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE);
    this.displayStepValidation = true;

    if (!this.onboardingState.areMandatoryStepsCompleted) {
      return;
    }

    try {
      this.onboardingState.status = ONBOARDING_STATUS.COMPLETED;
      await this.onboardingState.save();
      this.args.context.nextStepId = 'success';
      this.args.transitionToNext();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  onSkipTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.SKIP);
    try {
      let shouldContinueSkipping = true;

      if (!this.onboardingState.areMandatoryStepsCompleted) {
        let result = await this.openSkipModalTask.perform();
        shouldContinueSkipping = result === 'confirm';
        if (shouldContinueSkipping) {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.SKIP_CONFIRMED);
        }
      }
      if (shouldContinueSkipping) {
        this.onboardingState.status = ONBOARDING_STATUS.SKIPPED;
        await this.onboardingState.save();
        this.router.transitionTo('receivable-invoices.new');
      }
      return shouldContinueSkipping;
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  openSkipModalTask = dropTask(async () => {
    let link =
      this.args.context.source === ONBOARDING_SOURCE.RECURRING_INVOICES
        ? this.intl.t('section-title.recurring-invoices')
        : this.intl.t('section-title.client-invoices');

    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('receivable-invoices.onboarding.summary.skip-modal.title'),
      description: this.intl.t('receivable-invoices.onboarding.summary.skip-modal.description', {
        link,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.onboarding.ctas.skip'),
    });
  });

  onFileUploadFinishTask = dropTask(async () => {
    this.isWaitingForOCR = true;

    let ocrSuccessEvent = this.notifierManager.waitForEventTask.perform(
      ONBOARDING_OCR_SUCCEDED_EVENT
    );
    let ocrFailureEvent = this.notifierManager.waitForEventTask.perform(
      ONBOARDING_OCR_FAILED_EVENT
    );

    let websocket = await race([
      ocrFailureEvent,
      ocrSuccessEvent,
      rawTimeout(ONBOARDING_WEBSOCKET_TIMEOUT_MS),
    ]);

    switch (websocket?.event) {
      case ONBOARDING_OCR_SUCCEDED_EVENT:
        this.isWaitingForOCR = false;
        this.store.pushPayload('accounts-receivable-onboarding', {
          data: {
            id: websocket.payload.object_id,
            type: 'accounts_receivable_onboarding',
            attributes: websocket.payload.object,
          },
        });
        break;
      case ONBOARDING_OCR_FAILED_EVENT:
        this.isWaitingForOCR = false;
        this.toastFlashMessages.toastInfo(
          this.intl.t('receivable-invoices.onboarding.toasts.info.ocr-failed')
        );
        break;
      default:
        this.hasOCRTimedOut = true;
        break;
    }
  });
}
