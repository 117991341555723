export default {
  "row": "is",
  "row-active": "iF",
  "cell": "ig body-2",
  "terminated": "iD",
  "amount": "iV body-1",
  "item-name-cell": "iL",
  "status-icon": "iT",
  "item-name-container": "iz",
  "item-info": "ia",
  "name": "iH body-1",
  "status-displayed": "iO caption"
};
