export default {
  "wrapper": "cOh",
  "header": "cOf",
  "fadeIn": "cOK",
  "header-title": "cOG title-4",
  "transactions-container": "cOr",
  "placeholder-title": "cOb",
  "transaction-item-list-container": "cOy",
  "transaction-item-list": "cOU",
  "item": "cOj"
};
