export default {
  "account-container": "cmC",
  "badge": "cmk",
  "subtitle": "cmJ title-4",
  "accounts-filter": "cms",
  "account-filter-label": "cmF",
  "status": "cmg",
  "account-info": "cmD",
  "balance": "cmV",
  "revealed-balance": "cmL",
  "hidden-balance": "cmT"
};
