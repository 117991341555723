export default {
  "wrapper": "ov",
  "legend": "oo",
  "grid": "on",
  "subtitle": "oi body-2",
  "fiscal-code": "oA",
  "personal-data-name": "oY",
  "personal-data-birth": "oh",
  "personal-data": "of",
  "tax-residence": "oK",
  "payer-agent": "oG",
  "errors": "or",
  "business": "ob",
  "address": "oy"
};
