export default {
  "container": "Kq",
  "numbering": "KZ",
  "sideview": "KR",
  "form": "KQ",
  "numbering-fields": "Ke",
  "row": "KB",
  "label-tooltip": "KE",
  "tooltip-icon": "Kd",
  "legend": "KI",
  "next-invoice-number": "Kt",
  "pdf-preview": "KM",
  "de-layout": "KP",
  "de-pdf-preview": "Kl"
};
