export default {
  "banner": "cTH",
  "inner": "cTO",
  "header": "cTm title-3",
  "content": "cTp",
  "subtitle": "cTx body-1",
  "list": "cTw",
  "item": "cTN body-1",
  "icon": "cTv",
  "cta": "cTo btn btn--primary",
  "spinner": "cTn",
  "illustration": "cTi",
  "image": "cTA"
};
