export default {
  "container": "cHx",
  "indicator-container": "cHw",
  "transactions-container": "cHN",
  "indicator": "cHv",
  "cashflow-container": "cHo",
  "cashflow": "cHn",
  "donut-container": "cHi",
  "donut": "cHA"
};
