export default {
  "item": "cgu",
  "selected": "cDS",
  "hover-revised": "cDc",
  "asset-container": "cDq",
  "asset": "cDZ",
  "revoked-icon": "cDR",
  "pending-icon": "cDQ",
  "revoked": "cDe",
  "revised-revoke": "cDB",
  "pending": "cDE",
  "revised-pending": "cDd",
  "invitable": "cDI",
  "info": "cDt",
  "name": "cDM",
  "email": "cDP",
  "actions": "cDl",
  "invitable-member-delete": "cDX",
  "invitable-member-invite": "cDC"
};
