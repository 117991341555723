export default {
  "integration-item": "clF",
  "icon-container": "clg",
  "content": "clD",
  "title-container": "clV",
  "new-badge": "clL",
  "title": "clT",
  "description": "clz",
  "no-attributes": "cla",
  "connect-cta": "clH"
};
