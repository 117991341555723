export default {
  "card": "bg",
  "asset": "bD",
  "container": "bV",
  "subtitle": "bL",
  "header": "bT",
  "header-name": "bz",
  "option-list": "ba",
  "feature": "bH",
  "highlighted": "bO"
};
