export default {
  "container": "ckd",
  "form": "ckI",
  "form-header": "ckt",
  "form-subtitle": "ckM",
  "form-input": "ckP",
  "left-input": "ckl",
  "right-input": "ckX",
  "label-fix": "ckC",
  "x-validated-input__header": "ckk",
  "form-dropzone": "ckJ",
  "form-footer": "cks",
  "submit-cta": "ckF",
  "checklist": "ckg",
  "about-you-form": "ckD",
  "name-inputs": "ckV",
  "birth-inputs": "ckL"
};
