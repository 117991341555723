export default {
  "container": "rO",
  "back-button": "rm",
  "card-list": "rp",
  "upsell-badge": "rx",
  "pricing": "rw",
  "pricing-period": "rN",
  "ad-modal-button": "rv",
  "eligibility-disclaimer": "ro",
  "feature": "rn",
  "highlighted": "ri"
};
