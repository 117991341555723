export default {
  "members-loading": "cVc",
  "members-loading--ff": "cVq",
  "header": "cVZ",
  "header-search-bar": "cVR",
  "body": "cVQ",
  "members": "cVe",
  "placeholder-container": "cVB",
  "icon": "cVE",
  "details": "cVd",
  "member-loading": "cVI",
  "member-loading--no-ff": "cVt",
  "body-members": "cVM",
  "body-details": "cVP"
};
