export default {
  "container": "cdt",
  "content": "cdM",
  "sticky": "cdP",
  "divider": "cdl",
  "tooltip": "cdX",
  "detail": "cdC",
  "detail-value": "cdk",
  "color-secondary": "cdJ",
  "beneficiary-information": "cds",
  "beneficiary-source": "cdF",
  "currency": "cdg",
  "break-word": "cdD",
  "disclaimers": "cdV",
  "sticky-panel": "cdL"
};
