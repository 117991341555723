export default {
  "y-axis-placeholder": "czO",
  "y-axis-navigable": "czm",
  "y-axis-tick": "czp",
  "x-axis-placeholder": "czx",
  "x-axis-navigable": "czw",
  "x-axis-tick-wrapper": "czN",
  "x-axis-tick": "czv",
  "chart-loading-renderer": "czo"
};
