/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import { notifyPropertyChange } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class NotificationSettingsOptionComponent extends Component {
  @service intl;

  translations = {
    'account.name': {
      title: this.intl.t('users.subscriptions.account.name'),
    },
    'account.activity': {
      title: this.intl.t('users.subscriptions.account.activity'),
    },
    'account.activity_general': {
      title: this.intl.t('users.subscriptions.account.activity_general'),
      description: this.intl.t('users.subscriptions.account.activity_general_description'),
    },
    'account.monthly_invoice_reporting': {
      title: this.intl.t('users.subscriptions.account.activity_invoices'),
      description: this.intl.t('users.subscriptions.account.activity_invoices_description'),
    },
    'account.activity_matching': {
      title: this.intl.t('users.subscriptions.account.activity_matching'),
      description: this.intl.t('users.subscriptions.account.activity_matching_description'),
    },
    'account.activity_reporting': {
      title: this.intl.t('users.subscriptions.account.activity_reporting'),
      description: this.intl.t('users.subscriptions.account.activity_reporting_description'),
    },
    'account.activity_transaction': {
      title: this.intl.t('users.subscriptions.account.activity_transaction'),
      description: this.intl.t('users.subscriptions.account.activity_transaction_description'),
    },
    'account.activity_comments': {
      title: 'Comments',
      description: 'Get notifications for comments on your transactions.',
    },
    'account.transaction_failed': {
      title: this.intl.t('users.subscriptions.account.transaction_failed'),
    },
    'account.transaction_successful': {
      title: this.intl.t('users.subscriptions.account.transaction_successful'),
    },
    'budgets.name': {
      title: this.intl.t('users.subscriptions.budgets.name'),
    },
    'budgets.budgets': {
      title: this.intl.t('users.subscriptions.budgets.title'),
      description: this.intl.t('users.subscriptions.budgets.description'),
    },
    'card.activity': {
      title: this.intl.t('users.subscriptions.card.activity'),
    },
    'card.activity_general': {
      title: this.intl.t('users.subscriptions.card.activity_general'),
      description: this.intl.t('users.subscriptions.card.activity_general_description'),
    },
    'card.activity_transaction': {
      title: this.intl.t('users.subscriptions.card.activity_transaction'),
      description: this.intl.t('users.subscriptions.card.activity_transaction_description'),
    },
    'card.name': {
      title: this.intl.t('users.subscriptions.card.name'),
    },
    'card.payment_failed': {
      title: this.intl.t('users.subscriptions.card.payment_failed'),
    },
    'card.payment_successful': {
      title: this.intl.t('users.subscriptions.card.payment_successful'),
    },
    'card.settings': {
      title: this.intl.t('users.subscriptions.card.settings'),
    },
    'check.name': {
      title: this.intl.t('users.subscriptions.check.name'),
    },
    'check.check': {
      title: this.intl.t('users.subscriptions.check.check'),
      description: this.intl.t('users.subscriptions.check.check_description'),
    },
    'company.activity': {
      title: this.intl.t('users.subscriptions.company.activity'),
    },
    'company.name': {
      title: this.intl.t('users.subscriptions.company.name'),
    },
    'company.team': {
      title: this.intl.t('users.subscriptions.company.team'),
    },
    'request.name': {
      title: this.intl.t('users.subscriptions.request.name'),
    },
    'request.transfer': {
      title: this.intl.t('users.subscriptions.request.transfer'),
      description: this.intl.t('users.subscriptions.request.transfer_description'),
    },
  };

  get notificationOptions() {
    let key = 'account.name';

    if (this.args.catCode && this.args.option.notCode) {
      key = `${this.args.catCode}.${this.args.option.notCode}`;
    }

    return this.translations[key];
  }

  handleUpdateTask = restartableTask(async attribute => {
    this.args.option.devices[attribute] = !this.args.option.devices[attribute];
    notifyPropertyChange(this.args.option.devices, attribute);
    await timeout(DEBOUNCE_MS);
    await this.args.option.save({
      adapterOptions: {
        headers: { 'X-Qonto-Organization-ID': this.args.organization.id },
      },
    });
  });
}
