export default {
  "card": "cFe",
  "focus": "cFB",
  "title-format": "cFE",
  "header": "cFd",
  "headings": "cFI",
  "title": "cFt title-4",
  "main": "cFM title-3",
  "footer": "cFP"
};
