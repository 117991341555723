export default {
  "wrapper": "cet",
  "confirm": "ceM",
  "plan-info": "ceP",
  "plan-info-left": "cel",
  "info-title": "ceX body-1",
  "initial-trial-content": "ceC",
  "extra-fees-item": "cek body-2",
  "extra-fees-container": "ceJ",
  "bottom-wrapper": "ces",
  "total-price-container": "ceF",
  "extra-fees-element": "ceg body-1",
  "border": "ceD",
  "subtitle": "ceV body-2",
  "group-item": "ceL",
  "amount": "ceT",
  "subscription-billing-summary": "cez",
  "summary": "cea",
  "summary-list": "ceH",
  "disclaimer": "ceO",
  "disclaimer-revamp": "cem",
  "error": "cep",
  "error-link": "cex",
  "warning-wrapper": "cew",
  "warning-label": "ceN body-1"
};
