export default {
  "page-wrapper": "ur",
  "container": "ub",
  "content": "uy",
  "showElement": "uU",
  "illustration": "uj",
  "fadeIn": "uW",
  "scaleDown": "uu",
  "error-content": "cSS",
  "error-illustration": "cSc"
};
