export default {
  "sidebar": "csc",
  "top-section": "csq",
  "title": "csZ",
  "primary-action": "csR",
  "edit-icon": "csQ",
  "close-icon": "cse",
  "box-wrapper": "csB",
  "bottom-action": "csE",
  "related-invoices": "csd",
  "related-invoices-title": "csI mb-16 caption-bold",
  "box": "cst",
  "box-element": "csM",
  "cancel-text": "csP",
  "box-header": "csl",
  "row": "csX",
  "greyed-out": "csC",
  "struck-through": "csk",
  "share-mandate-link": "csJ"
};
