export default {
  "label-wrapper": "jS body-1",
  "avatar": "jc",
  "container": "jq",
  "transaction-info": "jZ",
  "secondary": "jR",
  "amount-container": "jQ",
  "amount": "je",
  "disabled": "jB",
  "checkbox": "jE"
};
