export default {
  "card": "cMx",
  "text-container": "cMw",
  "text-title": "cMN title-3",
  "text-subtitle": "cMv body-1",
  "text-cta": "cMo body-1",
  "text-cta-icon": "cMn",
  "image": "cMi",
  "image-illustration": "cMA"
};
