export default {
  "header": "cJr",
  "center": "cJb",
  "header-placeholder": "cJy",
  "placeholder": "cJU",
  "name-block": "cJj",
  "block": "cJW",
  "detail-block": "cJu",
  "border-top": "csS"
};
