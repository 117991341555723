export default {
  "wrapper": "np",
  "legend": "nx",
  "input": "nw",
  "table": "nN",
  "footer": "nv",
  "footer-total-m": "no",
  "footer-total-n": "nn",
  "footer-balance": "ni"
};
