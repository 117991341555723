/* import __COLOCATED_TEMPLATE__ from './fr-terms-and-conditions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PLACEHOLDER_SECTIONS } from '@qonto/ui-kit/addon/constants/pdf-preview';
import { dropTask } from 'ember-concurrency';

import { ONBOARDING_STEP_STATUS } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
export default class FlowsAccountReceivableOnboardingTermsAndConditions extends Component {
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service networkManager;
  @service sentry;

  @tracked showTermsLinkFields = Boolean(this.settings.tcLinkUrl || this.settings.tcLinkText);

  willDestroy() {
    super.willDestroy(...arguments);

    if (!this.settings || this.settings.isDestroyed || this.settings.isDestroying) return;

    this.settings.rollbackAttributes();
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get settings() {
    return this.args.context.settings;
  }

  get onboardingState() {
    return this.args.context.onboardingState;
  }

  get stepStatus() {
    return this.onboardingState.substepStatusCompanyDetails;
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: [
        PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS,
        PLACEHOLDER_SECTIONS.NUMBER,
        PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION,
      ],
    };
  }

  get exampleInvoice() {
    return {
      organization: {
        legalCountry: this.organization.legalCountry,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
      },
      contactEmail: this.settings.contactEmail,
      number: this.nextInvoiceNumberPreview,
    };
  }

  @action
  handleAddTermsConditionsLink() {
    this.showTermsLinkFields = true;
  }

  @action
  handleRemoveTermsConditionsLink() {
    this.showTermsLinkFields = false;
    this.settings.setProperties({
      tcLinkText: null,
      tcLinkUrl: null,
    });
  }

  @action
  handleFocusOutTcLinkUrl() {
    if (this.settings.tcLinkUrl) {
      this.settings.setProperties({
        tcLinkUrl: this._normalizeLinkUrl(this.settings.tcLinkUrl),
      });
    }
  }

  @action
  handleSkipCTA() {
    this.args.backToStep('summary');
  }

  confirmTask = dropTask(async () => {
    try {
      await this.settings.save();
      this.onboardingState.substepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
      await this.onboardingState.save();
      this.args.backToStep('summary');
    } catch (error) {
      if (error.status !== 422) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  /**
   * @todo: This can be refactored to use URL.parse() after updating core-js to 3.38.0
   */
  _normalizeLinkUrl(urlInput) {
    try {
      let normalizedUrl = new URL(urlInput);
      if (normalizedUrl.protocol !== 'https:') {
        return urlInput.replace(/(.*):\/\//, 'https://');
      }
      return urlInput;
    } catch {
      return `https://${urlInput}`;
    }
  }
}
