export default {
  "item-status": "cOT",
  "item-date": "cOz",
  "item-amount": "cOa body-1",
  "item-amount-credit": "cOH",
  "row": "cOO",
  "icon": "cOm",
  "disabled": "cOp",
  "icon-container": "cOx",
  "text-container": "cOw",
  "text-row": "cON",
  "item-details": "cOv",
  "counterparty-name": "cOo",
  "column": "cOn",
  "item-amount-disabled": "cOi",
  "attachment-tooltip": "cOA",
  "bank-account-avatar": "cOY"
};
