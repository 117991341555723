/* import __COLOCATED_TEMPLATE__ from './compare-roles.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from '@repo/design-system-kit';

import { Feature } from 'qonto/react/components/flows/member/compare-roles';

export default class FlowsInviteCompareRolesComponent extends Component {
  feature = Feature;

  @service intl;
  @service abilities;
  @service organizationManager;

  @tracked isScrolling = false;

  badgeHighlight = Badge;

  featureGroups = [
    {
      title: this.intl.t('invitation-flow.compare-roles.group.accounts.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.accounts.access'),
          roles: {
            employee: this.intl.t('invitation-flow.compare-roles.feature.no-access'),
            manager: this.intl.t('invitation-flow.compare-roles.feature.custom-access'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.custom-access'),
            admin: this.intl.t('invitation-flow.compare-roles.feature.full-access'),
            reporting: this.intl.t('invitation-flow.compare-roles.feature.full-access'),
          },
        },
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.accounts.financial-monitoring'
          ),
          roles: {
            employee: false,
            manager: false,
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.transactions.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.transactions.view-edit'),
          roles: {
            employee: this.intl.t('invitation-flow.compare-roles.feature.on-their-own'),
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.cards.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.cards.pay'),
          roles: {
            employee: true,
            manager: true,
            advanced_manager: true,
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.expense-management.title'),
      features: [
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.card-requests'
          ),
          roles: {
            employee: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.transfer-requests'
          ),
          roles: {
            employee: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
          },
        },
        ...(this.abilities.can('use mileages request')
          ? [
              {
                description: this.intl.t(
                  'invitation-flow.compare-roles.group.expense-management.expense-reports-mileage'
                ),
                roles: {
                  employee: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
                  manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
                  advanced_manager: this.intl.t(
                    'invitation-flow.compare-roles.feature.customizable'
                  ),
                  admin: true,
                  reporting: false,
                },
              },
            ]
          : this.abilities.can('use expense reports request')
            ? [
                {
                  description: this.intl.t(
                    'invitation-flow.compare-roles.group.expense-management.expense-reports'
                  ),
                  roles: {
                    employee: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
                    manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
                    advanced_manager: this.intl.t(
                      'invitation-flow.compare-roles.feature.customizable'
                    ),
                    admin: true,
                    reporting: false,
                  },
                },
              ]
            : []),
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.supplier-invoices'
          ),
          roles: {
            employee: false,
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.direct-debits'
          ),
          roles: {
            employee: false,
            manager: this.hasModularPricing
              ? false
              : this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          description: this.intl.t('invitation-flow.compare-roles.group.expense-management.checks'),
          roles: {
            employee: false,
            manager: this.hasModularPricing
              ? false
              : this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.team-management.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.team-management.manage'),
          roles: {
            employee: false,
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.bookkeeping.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.bookkeeping.organize'),
          roles: {
            employee: false,
            manager: this.hasModularPricing
              ? false
              : this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.qonto-connect.title'),
      features: [
        {
          description: this.intl.t('invitation-flow.compare-roles.group.qonto-connect.connect'),
          roles: {
            employee: false,
            manager: false,
            advanced_manager: false,
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      title: this.intl.t('invitation-flow.compare-roles.group.multi-accounts.title'),
      features: [
        {
          description: this.intl.t(
            'invitation-flow.compare-roles.group.multi-accounts.open-accounts'
          ),
          roles: {
            employee: false,
            manager: false,
            advanced_manager: false,
            admin: true,
            reporting: false,
          },
        },
      ],
    },
  ];

  @action updateScrolledTop(value) {
    this.isScrolling = !value;
  }

  get hasModularPricing() {
    return this.organizationManager.organization?.hasModularPricing;
  }
}
