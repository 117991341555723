export default {
  "wrapper": "cMB",
  "close-button": "cME",
  "content-wrapper": "cMd",
  "main": "cMI",
  "main-content": "cMt",
  "description": "cMM",
  "mt-26": "cMP",
  "mt-62": "cMl",
  "user-prompt": "cMX",
  "user-prompt-inappropriate-warning": "cMC",
  "logo-preview": "cMk",
  "static-loader": "cMJ",
  "loading-state-illustration": "cMs",
  "sticky-panel": "cMF"
};
