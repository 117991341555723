export default {
  "container": "fB",
  "company-details": "fE",
  "sideview": "fd",
  "form": "fI",
  "french-company-details-fieldset": "ft",
  "footer": "fM",
  "pdf-preview": "fP",
  "de-layout": "fl",
  "de-pdf-preview": "fX",
  "german-preview": "fC"
};
