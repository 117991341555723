export default {
  "fieldset": "cJe",
  "subscription-period": "cJB",
  "automatic-number-section": "cJE",
  "automatic-number-label": "cJd",
  "icon-tooltip": "cJI",
  "date-picker": "cJt",
  "label": "cJM",
  "header-text-field": "cJP",
  "header-text-field-container": "cJl",
  "header-text-field-add-button": "cJX btn btn--tertiary",
  "header-text-field-close-button": "cJC btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cJk",
  "read-only": "cJJ"
};
