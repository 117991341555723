export default {
  "cell": "YA",
  "empty": "YY",
  "supplier": "Yh",
  "supplier-content": "Yf",
  "supplier-avatar": "YK mr-16",
  "installments": "YG body-2",
  "overflow-hidden": "Yr",
  "ellipsis": "Yb",
  "completed-on": "Yy",
  "financed-amount": "YU body-2",
  "amount": "Yj",
  "total-repayment": "YW body-1"
};
