export default {
  "guests-loading": "cPd",
  "header": "cPI",
  "body": "cPt",
  "members": "cPM",
  "title": "cPP caption-bold",
  "placeholder-container": "cPl",
  "icon": "cPX",
  "details": "cPC"
};
