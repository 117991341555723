export default {
  "sidebar-section": "cCc body-2",
  "sidebar-section-header": "cCq body-1 mb-4",
  "providers-logos": "cCZ",
  "risks": "cCR",
  "tag": "cCQ tag",
  "external-link-icon": "cCe",
  "disclaimer": "cCB",
  "divider": "cCE"
};
