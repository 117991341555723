export default {
  "journey": "cRm",
  "content": "cRp",
  "distance": "cRx",
  "google-link-container": "cRw",
  "link-icon": "cRN",
  "continue-btn": "cRv",
  "origin": "cRo",
  "input-width": "cRn"
};
