export default {
  "wrapper": "nB",
  "legend": "nE",
  "operation-code": "nd",
  "table": "nI",
  "footer": "nt",
  "footer-detraction": "nM",
  "footer-total-g": "nP",
  "footer-total-h": "nl",
  "footer-net-g-h": "nX"
};
