export default {
  "container": "ctG",
  "container--collapsed": "ctr",
  "spin": "ctb",
  "cta-tooltip": "cty",
  "cta--collapsed": "ctU",
  "cta--expanded": "ctj",
  "collapsed-svg-icon": "ctW",
  "expanded-svg-icon": "ctu",
  "generate-logo-cta": "cMS",
  "badge": "cMc",
  "scaleDown": "cMq",
  "fadeIn": "cMZ",
  "textRiseButton": "cMR",
  "cta-description": "cMQ",
  "textRiseDescription": "cMe"
};
