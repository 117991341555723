export default {
  "invitations-password-form": "ckY",
  "mobile-mode": "ckh",
  "logo": "ckf",
  "header": "ckK",
  "controls": "ckG",
  "footer": "ckr",
  "submit-cta": "ckb",
  "subtitle": "cky"
};
