export default {
  "container": "fY",
  "customize-field": "fh",
  "content": "ff",
  "sideview": "fK",
  "form": "fG",
  "footer": "fr",
  "link-fields": "fb",
  "pdf-preview": "fy"
};
