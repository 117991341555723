export default {
  "wrapper": "cQW",
  "wrapper-revamp": "cQu",
  "container": "ceS",
  "container-revamp": "cec",
  "box-wrapper": "ceq",
  "warning-label": "ceZ",
  "subtitle-container": "ceR",
  "info-container": "ceQ",
  "card": "cee",
  "disclaimer": "ceB",
  "list": "ceE",
  "list-item": "ced body-2",
  "total": "ceI"
};
