export default {
  "mt-16": "cVk",
  "title": "cVJ",
  "edit-organization-form": "cVs",
  "beside-block": "cVF",
  "info-circle": "cVg",
  "hollow": "cVD",
  "filled": "cVV",
  "radio": "cVL",
  "cancel-button": "cVT",
  "error-message": "cVz"
};
