export default {
  "container": "yg",
  "centered": "yD",
  "content": "yV",
  "spinner": "yL",
  "holder-selector": "yT",
  "holder-selector-error": "yz",
  "info-text": "ya",
  "error-text": "yH",
  "lottie-illustration": "yO",
  "info-text-1": "ym",
  "info-text-2": "yp"
};
