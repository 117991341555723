export default {
  "container": "cdS",
  "content": "cdc",
  "quote-information-item": "cdq",
  "divider": "cdZ",
  "tooltip": "cdR",
  "amount": "cdQ title-4",
  "align-left": "cde",
  "sticky-panel": "cdB"
};
