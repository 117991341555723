export default {
  "row": "cTQ",
  "terminated": "cTe",
  "status-avatar": "cTB",
  "amount": "cTE body-1",
  "item-info": "cTd",
  "date-cell": "cTI",
  "cell": "cTt body-2",
  "status-icon": "cTM",
  "amount-cell": "cTP",
  "item-name-cell": "cTl",
  "item-name-container": "cTX"
};
