export default {
  "step-container": "uH",
  "content": "uO",
  "section": "um",
  "section-content": "up",
  "section-content-text": "ux",
  "section-illustration": "uw",
  "close-form-cta": "uN",
  "bank-account-select": "uv"
};
