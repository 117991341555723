export default {
  "form": "AH",
  "fieldset": "AO",
  "form-body": "Am",
  "conditional-select": "Ap",
  "conditional-text": "Ax",
  "group": "Aw",
  "add-filter-button": "AN btn btn--tertiary",
  "footer": "Av",
  "btn-icon": "Ao",
  "add-icon": "An",
  "cancel-button": "Ai btn",
  "form-header": "AA",
  "align-start": "AY",
  "align-right": "Ah",
  "button-wrapper": "Af",
  "wrapper": "AK",
  "label": "AG body-2",
  "input-wrapper": "Ar",
  "input": "Ab input-field body-2",
  "error": "Ay",
  "error-message": "AU"
};
