export default {
  "details": "cRE",
  "disclaimer": "cRd",
  "data": "cRI",
  "confirm": "cRt",
  "form": "cRM",
  "textarea": "cRP",
  "preview": "cRl",
  "form-title": "cRX title1",
  "form-subtitle": "cRC title2",
  "divider": "cRk",
  "submit": "cRJ",
  "doc": "cRs",
  "amount-input-error": "cRF"
};
