export default {
  "container": "YQ",
  "header-wrapper": "Ye mb-16",
  "header": "YB",
  "logo": "YE mr-16",
  "title": "Yd title-4",
  "description": "YI body-2",
  "conditions": "Yt",
  "label": "YM caption mb-4",
  "tags": "YP"
};
