export default {
  "bucket-item": "clQ",
  "button": "cle",
  "content": "clB",
  "bucket-icon": "clE",
  "bucket-title-container": "cld",
  "bucket-new-badge": "clI",
  "bucket-title": "clt",
  "arrow-icon": "clM"
};
