/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class InvoicesTableHeaderComponent extends Component {
  @service intl;

  get subscriptionPeriodText() {
    let startDate = dateToken({
      date: this.args.fromDate,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_S,
    });

    let endDate = dateToken({
      date: this.args.toDate,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    return this.intl.t('subscription.invoice.table.period.value', { startDate, endDate });
  }

  get checkboxAriaLabel() {
    return `${this.intl.t('subscription.invoice.table.aria-labels.select-invoice')} ${
      this.subscriptionPeriodText
    }`;
  }

  get buttonAriaLabel() {
    // If items are selected, action of button is to select
    if (this.args.someSelected || this.args.allSelected) {
      return this.checkboxAriaLabel;
    }

    return `${this.intl.t('subscription.invoice.table.aria-labels.download-invoice')} ${
      this.subscriptionPeriodText
    }`;
  }
}
