export default {
  "header-cell": "it caption-bold",
  "sortable": "iM",
  "animated": "iP",
  "fadein-item": "il",
  "animated-cell": "iX",
  "col1": "iC",
  "header-content": "ik caption-bold",
  "active": "iJ"
};
