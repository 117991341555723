export default {
  "wrapper": "ccA",
  "promotion-title": "ccY",
  "comparison-table": "cch",
  "table-head": "ccf",
  "title-wrapper": "ccK",
  "illustration-wrapper": "ccG",
  "illustration": "ccr",
  "role-wrapper": "ccb",
  "role-name": "ccy",
  "role-disabled": "ccU",
  "role-access": "ccj",
  "disabled-caption": "ccW",
  "table-head-sticky": "ccu",
  "show-border": "cqS",
  "group-row": "cqc",
  "table-body": "cqq",
  "group": "cqZ",
  "table-body-row": "cqR",
  "feature-description": "cqQ body-2",
  "feature": "cqe body-2",
  "group-spacer": "cqB"
};
