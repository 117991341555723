export default {
  "step-container": "cEt",
  "content": "cEM",
  "beneficiary": "cEP",
  "beneficiary-name": "cEl",
  "trusted": "cEX",
  "amount-disclaimer": "cEC",
  "purpose-subtitle": "cEk",
  "purposes": "cEJ",
  "purpose-error": "cEs",
  "sticky-panel": "cEF"
};
