export default {
  "container": "cXp",
  "logo": "cXx",
  "title": "cXw title-4",
  "description": "cXN body-2",
  "metadata": "cXv",
  "risks-container": "cXo",
  "label": "cXn caption",
  "risks": "cXi",
  "tag": "cXA tag"
};
