export default {
  "invite-review": "cqi",
  "invite-review-info": "cqA",
  "invite-review-info--invitee": "cqY",
  "disclaimer-button": "cqh",
  "info-block": "cqf",
  "ev": "cqK",
  "tooltip": "cqG",
  "description": "cqr"
};
