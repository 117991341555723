export default {
  "body": "cDk",
  "members": "cDJ",
  "members-results": "cDs",
  "members-list": "cDF",
  "members-title": "cDg caption-bold",
  "member": "cDD",
  "empty-illustration": "cDV",
  "empty-title": "cDL title-3",
  "empty-description": "cDT body-2",
  "invitable-members": "cDz",
  "details": "cDa",
  "pagination-footer": "cDH"
};
