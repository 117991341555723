export default {
  "container": "GX",
  "container-content": "GC",
  "content": "Gk",
  "underline": "GJ",
  "inline-content": "Gs",
  "payment-rate": "GF",
  "payment-subtitle": "Gg",
  "grey-text": "GD",
  "earnings-header": "GV",
  "earnings-tooltip": "GL"
};
