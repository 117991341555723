export default {
  "body-loading": "cFj",
  "body-members": "cFW",
  "body-details": "cFu",
  "body-details-title": "cgS",
  "icon": "cgc",
  "body-details-header": "cgq",
  "body-details-ids-subtitle": "cgZ",
  "body-details-overview-subtitle": "cgR",
  "body-details-overview-content": "cgQ",
  "body-details-ctas": "cge",
  "body-details-ctas-left": "cgB",
  "body-details-ctas-right": "cgE",
  "right-edit": "cgd"
};
