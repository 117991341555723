export default {
  "upsell-cta": "cqu",
  "discover-badge": "cZS",
  "role-selection-item": "cZc",
  "unavailable": "cZq",
  "role-headline": "cZZ",
  "illustration": "cZR",
  "role-description": "cZQ",
  "description-item": "cZe",
  "description-icon": "cZB",
  "description-text": "cZE",
  "current-role": "cZd"
};
