export default {
  "wrapper": "ctI input-field mb-4",
  "error": "ctt",
  "inactive": "ctM",
  "hidden-value": "ctP",
  "percentage-sign": "ctl",
  "black": "ctX",
  "input-field": "ctC",
  "disabled": "ctk"
};
