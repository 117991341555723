export default {
  "wrapper": "nC",
  "subtitle": "nk body-2",
  "legend": "nJ",
  "table": "ns",
  "footer": "nF",
  "footer-total-c": "ng",
  "footer-total-d": "nD",
  "footer-balance": "nV"
};
