/* import __COLOCATED_TEMPLATE__ from './notifications-settings.hbs'; */
import { action, notifyPropertyChange } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Toggle } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { restartableTask, task, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CATEGORIES_ORDER,
} from 'qonto/constants/notifications';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class NotificationsSettings extends Component {
  toggle = Toggle;

  @service abilities;

  @tracked opened = false;

  transition = fade;

  @reads('args.membership.subscriptions') membershipSubscriptions;

  get notificationCategories() {
    let categories = {};

    this.membershipSubscriptions.forEach(subscription => {
      let catCode = subscription.catCode;
      let code = subscription.code;
      let organization = subscription.membership.get('organization');

      if (
        catCode === NOTIFICATION_CATEGORIES.BUDGETS &&
        (this.abilities.cannot('access budget') || this.abilities.cannot('read details budget'))
      ) {
        return;
      }

      if (
        catCode === NOTIFICATION_CATEGORIES.CHECK &&
        !organization.get('features').includes('checks')
      ) {
        return;
      }

      if (
        code === 'account_activity_matching' &&
        this.abilities.cannot('access email forward attachment')
      ) {
        return;
      }

      if (
        code === 'account_activity_comments' &&
        !variation('feature--boolean-redesigned-transactions-table')
      ) {
        return;
      }

      if (!categories[catCode]) {
        categories[catCode] = [];
      }
      categories[catCode].push(subscription);
    });

    return this.sortCategories(categories);
  }

  sortCategories(categories) {
    let sortedCategories = {};

    NOTIFICATION_CATEGORIES_ORDER.forEach(category => {
      if (categories[category]) {
        sortedCategories[category] = categories[category];
      }
    });

    return sortedCategories;
  }

  get hasSubscriptions() {
    let subscription = this.membershipSubscriptions.find(subscription => {
      let { email, push } = subscription.devices;
      return email || push;
    });

    return Boolean(subscription);
  }

  @action
  handleHeaderClick() {
    this.opened = !this.opened;
  }

  saveSubscriptionTask = task(async () => {
    await Promise.all(
      this.args.membership.subscriptions.map(subscription =>
        subscription.save({
          adapterOptions: {
            headers: { 'X-Qonto-Organization-ID': this.args.membership.organization.id },
          },
        })
      )
    );
  });

  handleToggleUpdateTask = restartableTask(async () => {
    let value = !this.hasSubscriptions;
    this.args.membership.subscriptions.forEach(subscription => {
      subscription.devices.email = value;
      subscription.devices.push = value;
    });

    notifyPropertyChange(this.args.membership, 'subscriptions');
    next(() => (this.opened = value));

    await timeout(DEBOUNCE_MS);

    await this.saveSubscriptionTask.perform();
  });
}
