export default {
  "filter": "iW",
  "expression": "iu",
  "conditional-wrapper": "AS mr-8",
  "dropdown-property-select": "Ac",
  "is-advanced-filters-mode": "Aq",
  "dropdown-operator-select": "AZ",
  "conditional-text": "AR",
  "property-select": "AQ",
  "operator-select": "Ae",
  "error-message": "AB",
  "values-field": "AE",
  "input-placeholder": "Ad",
  "filter-actions": "AI",
  "label-color": "At",
  "button-container": "AM"
};
