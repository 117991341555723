export default {
  "selector": "bY",
  "metal-design": "bh",
  "content": "bf",
  "header": "bK",
  "big": "bG",
  "header-name": "br",
  "subtitle": "bb",
  "button": "by",
  "features": "bU",
  "feature": "bj",
  "highlighted": "bW"
};
