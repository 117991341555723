export default {
  "container": "cCJ",
  "header": "cCs",
  "content": "cCF",
  "info-list": "cCg",
  "info-label": "cCD body-2",
  "info-value": "cCV body-2",
  "provider": "cCL",
  "policy-container": "cCT",
  "policy-label": "cCz body-2",
  "policy-number": "cCa body-2",
  "copy-to-clipboard": "cCH",
  "button": "cCO btn btn--primary btn--stretch btn--large"
};
