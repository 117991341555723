export default {
  "wrapper": "cOB",
  "header": "cOE",
  "fadeIn": "cOd",
  "header-content": "cOI",
  "with-cta": "cOt",
  "header-title": "cOM title-4",
  "advanced-filters-trigger": "cOP",
  "filters-button": "cOl",
  "filters-button-upsell": "cOX",
  "filters-upsell-item": "cOC",
  "filters-badge": "cOk",
  "chart-container": "cOJ",
  "chart-legend-container": "cOs mb-24",
  "chart-watermark-container": "cOF",
  "chart-watermark": "cOg",
  "placeholder-title": "cOD",
  "placeholder-subtitle": "cOV",
  "advanced-filters": "cOL"
};
