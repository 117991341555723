export default {
  "container": "cmG",
  "label": "cmr",
  "disabled": "cmb",
  "small": "cmy caption",
  "large": "cmU body-2",
  "caption": "cmj",
  "amount": "cmW",
  "right-aligned": "cmu",
  "expanded": "cpS",
  "amount--current": "cpc",
  "amount--total": "cpq",
  "amount--sep": "cpZ"
};
