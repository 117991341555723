/* import __COLOCATED_TEMPLATE__ from './invoice.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';

import { PAY_LATER_FLOW_ORIGIN, PAY_LATER_VALIDATION_ERROR_TYPES } from 'qonto/constants/financing';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import createPayLaterTransferValidations from 'qonto/validations/pay-later-transfer';

export default class FlowsTransfersSepaPayLaterPayByInvoiceComponent extends Component {
  @service financing;
  @service intl;
  @service segment;
  @service toastFlashMessages;

  @tracked isValidationEnabled = false;

  @cached
  get payLaterDataModel() {
    return this.#createPayLaterDataModel();
  }

  get originForErrorTrackingEvent() {
    let { context } = this.args;

    switch (context.origin) {
      case TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE:
        return PAY_LATER_FLOW_ORIGIN.TRANSFER_FLOW;
      case TRANSFER_FLOW_ORIGIN.PAY_LATER_APP_SUCCESS:
        return PAY_LATER_FLOW_ORIGIN.SUCCESS_SCREEN;
      case TRANSFER_FLOW_ORIGIN.EMAIL_DEEPLINK:
        return PAY_LATER_FLOW_ORIGIN.DEEPLINK;
      case TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT:
        return PAY_LATER_FLOW_ORIGIN.COCKPIT;
      case TRANSFER_FLOW_ORIGIN.OVERVIEW:
        return PAY_LATER_FLOW_ORIGIN.GET_STARTED_CARD;
      case PAY_LATER_FLOW_ORIGIN.PROMOTIONAL_CARD:
        return PAY_LATER_FLOW_ORIGIN.PROMOTIONAL_CARD;
      case PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY:
        return PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY;
    }
  }

  @action
  disableValidation() {
    this.isValidationEnabled = false;
  }

  onClickNextTask = dropTask(async sepaTransfer => {
    let { context, transitionToNext } = this.args;

    await sepaTransfer.validate();

    let hasBeneficiary = sepaTransfer.get('beneficiary').content;

    let isFormValid = await this.validateInlineForm();

    if (!isFormValid || !hasBeneficiary) {
      this._scrollToErrorField();
      return;
    }

    let { origin } = context;

    try {
      context.didValidateFinancing = false;
      await this.handleValidationTask.perform(context);
      context.didValidateFinancing = true;

      this.segment.track('pay-later-dedicated-flow_errors_detected', {
        origin: this.originForErrorTrackingEvent,
        error: context.validationError ? context.validationError.errors : ['no_error'],
      });
    } catch (error) {
      let findAttachmentIdError = error.errors?.find(
        ({ code }) => code === 'attachment_id_not_found'
      );
      if (error.status === 404 && findAttachmentIdError) {
        context.validationError = {
          type: PAY_LATER_VALIDATION_ERROR_TYPES.ATTACHMENT_ID,
          errors: [findAttachmentIdError.code],
        };
      } else {
        return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      }
    }

    if (!context.validationError) {
      this.segment.track('transfer-sepa_amount_submitted', {
        flow: 'byinvoice',
        origin,
      });
    }

    transitionToNext();
  });

  async validateInlineForm() {
    let { validations } = await this.payLaterDataModel.validate();

    this.isValidationEnabled = true;

    return validations.isValid;
  }

  handleValidationTask = task(async context => {
    let { sepaTransfer, invoice } = context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    let attachmentId = invoice.attachment.get('id');
    let iban = sepaTransfer.beneficiary.get('iban');
    let beneficiaryName = sepaTransfer.beneficiary.get('name');

    context.validationError = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      groupErrors: true,
    });
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  #createPayLaterDataModel() {
    let { amount, reference } = this.args.context.sepaTransfer;
    let PayLaterValidationModel = this.#createPayLaterValidationModel();

    class PayLaterDataModel extends EmberObject.extend(PayLaterValidationModel) {
      @service intl;
      @service localeManager;

      @tracked amount;
      @tracked reference;
    }

    return PayLaterDataModel.create(getOwner(this).ownerInjection(), { amount, reference });
  }

  #createPayLaterValidationModel() {
    let { minTransferAmount, availableCreditAmount } = this.args.context;

    return createPayLaterTransferValidations(
      minTransferAmount,
      availableCreditAmount,
      this.#getValidationMessage.bind(this)
    );
  }

  #getValidationMessage(minTransferAmount, availableCreditAmount) {
    return (key, value) => {
      if (value < minTransferAmount.value) {
        return this.#getMinimumAmountValidationMessage(minTransferAmount);
      } else if (value > availableCreditAmount.value) {
        return this.#getAvailableCreditValidationMessage(availableCreditAmount);
      }
    };
  }

  #getMinimumAmountValidationMessage(minTransferAmount) {
    let formattedAmount = this.intl.formatNumber(minTransferAmount.value, {
      style: 'currency',
      currency: minTransferAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.minimum-amount', {
      amount: formattedAmount,
    });
  }

  #getAvailableCreditValidationMessage(availableCreditAmount) {
    let formattedAmount = this.intl.formatNumber(availableCreditAmount.value, {
      style: 'currency',
      currency: availableCreditAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.available-credit', {
      amount: formattedAmount,
    });
  }
}
