export default {
  "wrapper": "cXG",
  "content": "cXr",
  "back": "cXb btn btn--tertiary",
  "heading": "cXy",
  "heading-content": "cXU",
  "logo": "cXj",
  "placeholder": "cXW",
  "description": "cXu body-2",
  "cta": "cCS"
};
