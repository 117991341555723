/* import __COLOCATED_TEMPLATE__ from './attachment.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { TrackedArray } from 'tracked-built-ins';

import { EVENTS } from 'qonto/constants/international-out/tracking';

export const UPLOAD_OPTIONS = {
  model: 'attachment',
  url: 'v3/attachments',
};

export const UPLOADER_OPTIONS = {
  allowedExtensions: '.png,.pdf,.jpeg,.jpg',
  filesLimit: 5,
  maxSize: 15 * 1e6,
};

export default class FlowsTransfersInternationalOutNewAttachmentComponent extends Component {
  button = Button;

  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;

  errors = new TrackedArray();

  get files() {
    return this.args.context.attachments.map(attachment => attachment.file).filter(Boolean);
  }

  get uploaderOptions() {
    return UPLOADER_OPTIONS;
  }

  get uploadOptions() {
    return {
      ...UPLOAD_OPTIONS,
      payload: {
        fileKey: `${UPLOAD_OPTIONS.model}[file]`,
        data: {
          [`${UPLOAD_OPTIONS.model}[organization_id]`]: this.organizationManager.organization.id,
          [`${UPLOAD_OPTIONS.model}[bank_account_id]`]: this.args.context.bankAccount.id,
        },
      },
    };
  }

  @action
  addFile(file, attachment) {
    this.segment.track(EVENTS.ATTACHMENT_UPLOADED);
    this.#normalizeFile(file);
    attachment.file = file;
    this.args.context.attachments = [...this.args.context.attachments, attachment];
  }

  @action
  deleteFile(file) {
    this.#removeAttachment(this.#findAttachmentByFile(file));
  }

  @action
  downloadFile(file) {
    window.open(this.#findAttachmentByFile(file).downloadUrl, '_blank');
  }

  @action
  previewFile(file) {
    this.modals.open('transfers/international-out/modals/file-preview', {
      file,
      isFullScreenModal: true,
      onDownload: this.downloadFile,
      onRemove: this.deleteFile,
    });
  }

  onSubmitTask = dropTask(async confirmTask => {
    if (!this.files.length) {
      this.errors.push({ message: this.intl.t('international-out.attachment.error.required') });
      return;
    }

    if (await confirmTask.perform()) {
      this.segment.track(EVENTS.ATTACHMENT_SUBMITTED);
      this.args.transitionToNext();
    }
  });

  #findAttachmentByFile(file) {
    return this.args.context.attachments.find(attachment =>
      file.id ? attachment.file.id === file.id : file.fileUrl.includes(attachment.id)
    );
  }

  #normalizeFile(file) {
    let { name, size, type, url } = file;

    setProperties(file, {
      fileContentType: type,
      filename: name,
      fileName: name,
      filesizeBytes: size,
      fileSize: size,
      fileUrl: url,
    });

    return file.readAsDataURL().then(data => {
      set(file, 'fileUrl', data);
    });
  }

  #removeAttachment(attachment) {
    this.args.context.attachments = [
      ...this.args.context.attachments.slice(0, this.args.context.attachments.indexOf(attachment)),
      ...this.args.context.attachments.slice(this.args.context.attachments.indexOf(attachment) + 1),
    ];
    attachment.unloadRecord();
    return attachment;
  }
}
