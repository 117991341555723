export default {
  "dates-filter": "AP",
  "dates-wrapper": "Al",
  "date-interval": "AX",
  "date-field-wrapper": "AC",
  "dates-conditional": "Ak",
  "placeholder": "AJ",
  "error": "As",
  "error-message": "AF body-2"
};
