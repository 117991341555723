export default {
  "animated": "rb",
  "container": "ry",
  "cards-selectors": "rU",
  "card-selector": "rj",
  "selector": "rW",
  "header": "ru",
  "footer": "bS",
  "skip-cta": "bc",
  "pricing-caption": "bq",
  "show": "bZ",
  "selectorFadeIn": "bR",
  "fadeIn": "bQ",
  "asset-one": "be",
  "onePlusAssetFadeIn": "bB",
  "assetMoveUp": "bE",
  "asset-plus": "bd",
  "asset-x": "bI",
  "xAssetFadein": "bt",
  "feature": "bM",
  "highlighted": "bP"
};
